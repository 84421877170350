<template>
  <div>
    <h1>Αρχική</h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",

  data: () => ({}),

  async created() {
    await this.$store.dispatch("getCurrencies");
  },

  computed: {
    ...mapGetters(["loading", "currencies"]),
  },

  methods: {},
};
</script>
